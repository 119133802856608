export class AddVendor {
  constructor(
    name = '',
    vendor = '',
    requiredDriver = false,
  ) {
    this.name = name
    this.vendor = vendor
    this.requiredDriver = requiredDriver
  }
}

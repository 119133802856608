export class EditVendor {
  constructor(
    name = '',
    vendorId = '',
    requiredDriver = false,
  ) {
    this.name = name
    this.vendorId = vendorId
    this.requiredDriver = requiredDriver
  }
}

<template>
  <div class="container-fluid">
    <div class="row">
    <div class="col-md-12 px-0">
      <dx-data-grid
        ref="dataGrid"
        :data-source="PrinterVendorDataSource"
        key-expr="id"
        :show-borders="true"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-sorting mode="single" />
        <dx-remote-operations
          :paging="true"
          :sorting="false"
          :filtering="false"
        />
        <!-- Id -->
        <dx-column
          data-field="id"
          :width="100"
        />
        <!-- Name -->
        <dx-column
          data-field="name"
        />
        <!-- VendorId -->
        <dx-column
          data-field="vendor"
        />
        <!-- Buttons -->
        <dx-column
          cell-template="selectionCellTemplate"
          :width="180"
        />
        <template #selectionCellTemplate="{ data }">
          <div>
            <button
              class="devextreme-labels-button"
              @click="labelsAction(data)"
            >
              Labels
            </button>
            <button
              class="devextreme-edit-button"
              @click="editAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-orange"
                icon="Edit2Icon"
              />
            </button>
            <button
              class="devextreme-remove-button"
              @click="deleteAction(data)"
            >
              <feather-icon
                class="devextreme-cell-color-red"
                icon="TrashIcon"
              />
            </button>
          </div>
        </template>
        <dx-paging :page-size="pagerOptions.pageSize" />
        <dx-pager
          :allowed-page-sizes="pagerOptions.allowedPageSizes"
          :show-info="pagerOptions.showInfo"
          :show-navigation-buttons="pagerOptions.showNavigationButtons"
          :show-page-size-selector="pagerOptions.showPageSizeSelector"
          :visible="pagerOptions.visible"
        />
      </dx-data-grid>
      <div id="app-container-add">
        <dx-util-popup
          :visible="addPopupVisible"
          :hide-on-outside-click="true"
          :show-title="true"
          :drag-enabled="true"
          :width="720"
          :height="275"
          position="center"
          title="Add Printer Vendor"
        >
          <dx-util-scroll-view>
            <div class="printermanagementmodal">
              <div class="printermanagement-container">
                <dx-util-form
                  id="form"
                  :form-data="addfordata"
                  label-mode="static"
                  :read-only="false"
                  :show-colon-after-label="true"
                  label-location="left"
                  :min-col-width="300"
                  col-count="1"
                />
              </div>
              <div class="printermanagement-footer">
                <dx-util-button
                  class="savebutton"
                  text="Save"
                  @click="save()"
                />
                <dx-util-button
                  class="cancelbutton"
                  text="Close"
                  @click="closeAddPopup()"
                />
              </div>
            </div>
          </dx-util-scroll-view>
        </dx-util-popup>
      </div>
      <div id="app-container-update">
        <dx-util-popup
          :visible="editPopupVisible"
          :hide-on-outside-click="true"
          :show-title="true"
          :drag-enabled="true"
          :width="720"
          :height="275"
          position="center"
          title="Edit Printer Vendor"
        >
          <dx-util-scroll-view>
            <div class="printermanagementmodal">
              <div class="printermanagement-container">
                <dx-util-form
                  id="form"
                  :form-data="editfordata"
                  label-mode="static"
                  :read-only="false"
                  :show-colon-after-label="true"
                  label-location="left"
                  :min-col-width="300"
                  col-count="1"
                />
              </div>
              <div class="printermanagement-footer">
                <dx-util-button
                  class="savebutton"
                  text="Edit"
                  @click="editLocal()"
                />
                <dx-util-button
                  class="cancelbutton"
                  text="Close"
                  @click="closeEditPopup()"
                />
              </div>
            </div>
          </dx-util-scroll-view>
        </dx-util-popup>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { Notify } from '@robustshell/utils/index'
import printerserverapi from '@/http/requests/printer/printerserver'
import { AddVendor } from '@/http/models/printer/entity/Vendor/addVendor'
import { EditVendor } from '@/http/models/printer/entity/Vendor/editVendor'
import { PrinterVendorDataSource } from './vendor'

export default {
  name: 'PrinterVendor',
  components: {
  },
  directives: {
  },
  mixins: [GridBase],
  data() {
    return {
      addPopupVisible: false,
      addVendorData: new AddVendor(),
      addfordata: {
        name: '',
        vendor: '',
        requiredDriver: false,
      },
      editPopupVisible: false,
      editVendorData: new EditVendor(),
      editid: 0,
      editfordata: {
        name: '',
        vendor: '',
        requiredDriver: false,
      },
      PrinterVendorDataSource,
    }
  },
  mounted() {
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.openAddPopup()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.refreshTable()
          },
        },
        location: 'after',
      })
    },
    openAddPopup() {
      if (this.addPopupVisible === true) { this.addPopupVisible = false }
      setTimeout(() => {
        this.addPopupVisible = true
      }, 100)
    },
    save() {
      // #region Validation
      this.addVendorData.name = this.addfordata.name
      this.addVendorData.vendor = this.addfordata.vendor
      this.addVendorData.requiredDriver = this.addfordata.requiredDriver
      // #endregion
      printerserverapi.addVendor(this.addVendorData).then(result => {
        if (result.body === null) {
          Notify.error('Printer vendor could not create')
        } else {
          Notify.success('Printer vendor created')
          this.addPopupVisible = false
          this.clearAddData()
          PrinterVendorDataSource.reload()
        }
      })
    },
    clearAddData() {
      this.addfordata = {
        name: '',
        vendor: '',
        requiredDriver: false,
      }
    },
    closeAddPopup() {
      this.addPopupVisible = false
    },
    editLocal() {
      if (this.editid === 0) {
        Notify.error('Unexpected vendor')
        return
      }
      this.editVendorData.name = this.editfordata.name
      this.editVendorData.vendor = this.editfordata.vendor
      this.editVendorData.requiredDriver = this.editfordata.requiredDriver
      //
      printerserverapi.editVendor(this.editid, this.editVendorData).then(result => {
        if (result.body === null) {
          Notify.error('Printer vendor could not update')
        } else {
          Notify.success('Printer vendor updated')
          this.editPopupVisible = false
          this.clearEditData()
          PrinterVendorDataSource.reload()
        }
      })
    },
    clearEditData() {
      this.editid = 0
      this.editfordata = {
        name: '',
        vendor: '',
        requiredDriver: false,
      }
    },
    editAction(e) {
      if (this.editPopupVisible === true) { this.editPopupVisible = false }
      setTimeout(() => {
        this.editPopupVisible = true
      }, 100)
      this.editid = e.row.data.id
      this.editfordata.name = e.row.data.name
      this.editfordata.vendor = e.row.data.vendor
      this.editfordata.requiredDriver = e.row.data.requiredDriver
    },
    labelsAction(e) {
      const id = e.row.data.id
      // todo:: move to url
      localStorage.setItem('printerVendorId', id)
      this.$router.push({
        name: 'printerlabels',
      })
    },
    refreshTable() {
      this.$refs.dataGrid.instance.state(null)
    },
    closeEditPopup() {
      this.editPopupVisible = false
    },
    deleteAction(e) {
      const { id } = e.row.data
      this.$bvModal
        .msgBoxConfirm(`Are you sure to delete this printer vendor? ${e.row.data.name}`, {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value === true) {
            printerserverapi.deleteVendor(id).then(result => {
              if (result.body === null) {
                Notify.error('Printer vendor could not delete')
              } else {
                Notify.success('Printer vendor deleted')
                PrinterVendorDataSource.reload()
              }
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '../../../../assets/scss/printermanagement/printermanagement.scss';
</style>
